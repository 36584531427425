<template>
  <b-table-simple responsive>
    <b-tbody>
      <b-tr>
        <b-th colspan="2" class="text-center">RECURSOS BIBLIOGRÁFICOS </b-th>
      </b-tr>
      <b-tr
        v-for="bibliography_type in bibliographicResourceTypesFilter"
        :key="`bibliography-${bibliography_type.id}`"
      >
        <b-td style="width: 25%">
          <strong class="mr-1">{{ bibliography_type.name }} </strong>
          <b-icon-exclamation-circle
            v-b-tooltip.v-secondary="
              'Puede añadir hasta ' +
              bibliography_type.max_amount +
              ' recursos bibliográficos'
            "
          ></b-icon-exclamation-circle>
          <div class="mt-1">
            <div
              v-if="
                allows_crud &&
                bibliography.filter((b) => b.type == bibliography_type.id)
                  .length < bibliography_type.max_amount &&
                oldMatter &&
                !oldMatter.is_closed &&
                isClosedOldMatterPartial(bibliography_type)
              "
            >
              <b-button
                @click="
                  $bvModal.show(
                    `create-bibliographic-resource-modal-${bibliography_type.id}-${matter_form_view}`
                  )
                "
                v-b-tooltip.v-secondary.noninteractive="
                  'Agregue los Recursos por separado'
                "
                variant="primary"
                size="sm"
              >
                Agregar Recurso
              </b-button>
              <b-modal
                :id="`create-bibliographic-resource-modal-${bibliography_type.id}-${matter_form_view}`"
                title="Crear Recurso Bibliográfico"
                size="lg"
                hide-footer
                no-enforce-focus
              >
                <NewRichTextEditor
                  :Type="bibliography_type"
                  @save="createBibliography"
                  @close="closeModal(bibliography_type.id)"
                ></NewRichTextEditor>
              </b-modal>
            </div>
            <div
              v-else-if="
                allows_crud &&
                oldMatter &&
                !oldMatter.is_closed &&
                isClosedOldMatterPartial(bibliography_type)
              "
              v-b-tooltip.v-secondary.noninteractive="
                'Supero el máximo de recursos bibliográficos'
              "
            >
              <b-button disabled variant="primary" size="sm">
                Agregar Recurso
              </b-button>
            </div>
          </div>
        </b-td>
        <b-td>
          <div>
            <div
              v-for="biblio in bibliography.filter(
                (b) => b.type == bibliography_type.id
              )"
              :key="`bibliography-${biblio.id}`"
              class="border-resource"
            >
              <div style="display: flex">
                <div
                  class="my-1 p-1 w-100 rich-text-content support-resource-content"
                  v-html="biblio.description"
                ></div>
                <div
                  v-if="
                    allows_crud &&
                    oldMatter &&
                    !oldMatter.is_closed &&
                    isClosedOldMatterPartial(bibliography_type)
                  "
                  class="container-btn-blibliographic"
                >
                  <div class="d-flex p-1">
                    <button-edit
                      tabindex="-1"
                      class="w-30"
                      @click="
                        $bvModal.show(
                          `edit-bibliographic-resource-modal-${biblio.id}-${matter_form_view}`
                        )
                      "
                    >
                    </button-edit>
                    <button-delete
                      tabindex="-1"
                      class="w-30"
                      @click="
                        deleteBibliography(biblio.id, bibliography_type.name)
                      "
                    ></button-delete>
                  </div>
                  <b-modal
                    :id="`edit-bibliographic-resource-modal-${biblio.id}-${matter_form_view}`"
                    title="Editar Recurso Bibliográfico"
                    size="lg"
                    hide-footer
                    no-enforce-focus
                  >
                    Copie y edite los contenidos por separado
                    <NewRichTextEditor
                      :Object="biblio"
                      :Text="biblio.description"
                      @save="updateBibliography"
                      @close="closeModal(biblio.id)"
                    ></NewRichTextEditor>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "BibliographicResourcesComponent",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    matter_form_view: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    filter_object_in_view: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bibliographies: [],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      bibliographicResourceTypes: names.BIBLIOGRAPHIC_RESOURCE_TYPES,
    }),
    bibliography() {
      return this.bibliographies;
    },
    bibliographicResourceTypesFilter() {
      if (this.filter_object_in_view) {
        return this.bibliographicResourceTypes.filter((x) =>
          this.$equals(x.name, "Obligatoria")
        );
      } else return this.bibliographicResourceTypes;
    },
  },
  methods: {
    isClosedOldMatterPartial(support_resource_type) {
      if (this.oldMatter) {
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        ) {
          if (this.$equals(support_resource_type.name, "Obligatoria")) {
            return !this.oldMatter.bibliography_locked_view;
          } else return true;
        } else {
          return !this.oldMatter.bibliography_locked_view;
        }
      } else return false;
    },
    deleteBibliography(bibliography_id, type_name) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la Bibliografía ${type_name}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful
            .Delete(`/teaching/bibliographic-resource/${bibliography_id}/`)
            .then(() => {
              let index = this.bibliography.findIndex(
                (b) => b.id == bibliography_id
              );
              if (index != -1) {
                this.$emit("change_bibliographic_resource");
                this.bibliography.splice(index, 1);
              }
            });
        }
      });
    },
    updateBibliography(biblio, text) {
      let item = {
        name: text,
        description: text,
      };
      this.$restful
        .Patch(`/teaching/bibliographic-resource/${biblio.id}/`, item)
        .then((response) => {
          const index = this.bibliography.findIndex((b) => b.id == biblio.id);
          if (index != -1) {
            this.$emit("change_bibliographic_resource");
            this.bibliography[index].description = response.description;
            this.$bvModal.hide(
              `edit-bibliographic-resource-modal-${biblio.id}-${this.matter_form_view}`
            );
          }
        });
    },
    createBibliography(text, bibliography_type) {
      if (this.oldMatter.id) {
        let item = {
          name: text,
          description: text,
          type: bibliography_type.id,
          matter: this.oldMatter.id,
        };
        this.$restful
          .Post(`/teaching/bibliographic-resource/`, item)
          .then((response) => {
            this.$emit("change_bibliographic_resource");
            this.bibliography.push(response);
            this.$bvModal.hide(
              `create-bibliographic-resource-modal-${bibliography_type.id}-${this.matter_form_view}`
            );
          });
      }
    },
    closeModal(resource_id) {
      this.$bvModal.hide(
        `edit-resource-modal-${resource_id}-${this.matter_form_view}`
      );
      this.$bvModal.hide(
        `create-resource-modal-${resource_id}-${this.matter_form_view}`
      );
    },
    fetchBibliography() {
      if (this.oldMatter) {
        this.$restful
          .Get(`/teaching/bibliographic-resource/?matter=${this.oldMatter.id}`)
          .then((response) => {
            this.bibliographies = response.sort((a, b) => a.id - b.id);
          });
      }
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_BIBLIOGRAPHIC_RESOURCE_TYPES);
    this.fetchBibliography();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.container-btn-blibliographic {
  display: flex;
  align-items: center;
}
.border-resource {
  border-bottom: 1px solid black;
}
.border-resource:last-child {
  border-bottom: none;
}
table {
  width: 100%;
  table-layout: auto;
}
table td {
  border: 1px solid black;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color) !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
}
@media print {
  .bibliography-content,
  .support-resource-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 800px !important;
  }
}
</style>